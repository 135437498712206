<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Monitoraggio MES</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <h2> Avvia registrazione dati:</h2>
        <form>  
          <ion-item v-if="lastMonitoraggio.stato=='2'">
            <label>Riferimento offerta: </label>
            <ion-input id="rifcli"  v-model="registrazione.rif_cliente"></ion-input>
          </ion-item>
          <br>
          <ion-button color="success" @click="inizioRegistrazione()" v-if="lastMonitoraggio.stato=='2'"> START </ion-button>
          <ion-button color="danger" @click="fineRegistrazione()" v-if="lastMonitoraggio.stato=='1'"> STOP </ion-button>
          <input type="hidden" v-model="registrazione.timestamp"> 
        </form>
        <br><br><br> 
        <ion-item>
          <ion-radio-group v-model="graficosel">
            <label>Visualizza:</label>
            <ion-item>
              <label>Temperatura </label>
              <ion-radio value="temp" style="margin:5px;"></ion-radio>
              <label> Umidità</label>
              <ion-radio value="umid" style="margin:5px;"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-item>
        <br>
        <b>Ultime rilevazioni:</b>  (Alle: {{now}})
        <br>
        <button v-if="graficosel=='temp'" @click="getLastTen()" style="margin:10px; margin-left:10px; background-color:white;">
          <img style="width:30px;height:30px;" src="images\refresh.png"> 
        </button> 
        <button v-if="graficosel=='umid'" @click="getLastTenUmidita()" style="margin:10px; margin-left:10px; background-color:white;">
          <img style="width:30px;height:30px;" src="images\refresh.png"> 
        </button> 
        <button v-if="graficosel==''" style="margin:10px; margin-left:10px; background-color:white;">
          <img style="width:30px;height:30px;" src="images\refresh.png"> 
        </button> 
        <a href="https://www.agsuite.eu/#" target="_blank">
          <img style="width:30px;height:30px;" src="images\external-link.png">
        </a>
        <br> 
        <ion-grid v-if="graficosel=='temp'">
          <ion-row>
            <ion-col><b>Sensore</b></ion-col> 
            <ion-col><b>Data</b></ion-col> 
            <ion-col><b>Temperatura</b></ion-col>
          </ion-row>
          <ion-row :value="s.sourceId" v-for="s,i in lastTemp" :key="'sonda-'+i">
            <ion-col>{{lastTemp[i].SourceId}}</ion-col>  
            <ion-col id="centr">{{moment.unix(lastTemp[i].Timestamp).format('MM-DD-YYYY H:mm')}}</ion-col> 
            <ion-col id="centr" v-if="lastTemp[i].Temperature!=850">{{lastTemp[i].Temperature}}</ion-col>
            <ion-col id="centr"  v-if="lastTemp[i].Temperature==850"  style="background-color:#ff4f4f"><b>ERRORE</b></ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid v-if="graficosel=='umid'">
          <ion-row>
            <ion-col><b>Sensore</b></ion-col> 
            <ion-col><b>Data</b></ion-col> 
            <ion-col><b>Umidita</b></ion-col>
          </ion-row>
          <ion-row :value="s.sourceId" v-for="s,i in lastUmid" :key="'sonda-'+i">
            <ion-col>{{lastUmid[i].SourceId}}</ion-col>  
            <ion-col id="centr">{{moment.unix(lastUmid[i].Timestamp).format('MM-DD-YYYY H:mm')}}</ion-col> 
            <ion-col id="centr">{{lastUmid[i].Humidity}}</ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons,
    IonCol, 
    IonContent,
    IonGrid, 
    IonHeader, 
    IonInput,
    IonItem,
    IonMenuButton, 
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle, 
    IonToolbar,
    toastController 
  } from '@ionic/vue';
  import moment from 'moment';

  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonCol,
      IonContent,
      IonGrid,
      IonHeader,
      IonInput,
      IonItem,
      IonMenuButton,
      IonPage,
      IonRow,
      IonRadio,
      IonRadioGroup,
      IonTitle,
      IonToolbar,
    },
    data : function(){
      return{
        strRisultati:[],
        formattedTime:[],
        formattedDay:[],
        date:[],
        temperature:[],
        lastTen:[],
        lastTemp:[],
        lastTenUmidita:[],
        lastUmid:[],
        now:'',
        moment:moment,
        graficosel:'',
        temperatura:{
          deviceId:0,
          user:0,
          temperatura:0,
          temperaturaMax:0,
          temperaturaMin:0,
        },
        umidita:{
          deviceId:0,
          user:0,
          umidita:0,
          umiditaMax:0,
          umiditaMin:0,
        },
        registrazione:{
          timestamp:'',
          rif_cliente:'',
        },
        monitoraggi:{
          id:'',
          timestampstart:'',
          timestampend:'',
          user:'',
          rif_cliente:'',
          stato:''
        },
        lastMonitoraggio:{
          stato:''
        }
      }
    },
    methods:{
      getUser(){
        this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
        //.then(async (response) => {
        //  console.log(response)
        //})
      },
      getTemperature(){
        this.axios.post(this.apiUrl + 'get-temperature', {}, this.headers)
        .then(async (response) => {
          this.risultati=response.data.result
          this.strRisultati= JSON.stringify(this.risultati)
          this.strRisultati = JSON.parse(this.strRisultati)
        })
      },
      getUmidita(){
        this.axios.post(this.apiUrl + 'get-umidita', {}, this.headers)
        .then(async (response) => {
          this.risultati=response.data.result
          this.strRisultati= JSON.stringify(this.risultati)
          this.strRisultati = JSON.parse(this.strRisultati)
        })
      },
      getLastTen(){
        this.axios.post(this.apiUrl + 'get-last-ten', {}, this.headers)
        .then(async (response) => {
          this.lastTen=response.data.data.Output
          this.now=response.data.now
          var lastTemp= [];
          for(var i=0; i<this.lastTen.length; i++){
            lastTemp.push(this.lastTen[i]);
          }
          for(var j=0;j<lastTemp.length;j++){
            this.lastTen.push(lastTemp[j]);
          }
          this.lastTemp=lastTemp;    
        })
      },
      getLastTenUmidita(){
        this.axios.post(this.apiUrl + 'get-last-umidita', {}, this.headers)
        .then(async (response) => {
          this.lastTenUmidita=response.data.data.Output
          this.now=response.data.now
          var lastUmid= [];
          for(var i=0; i<this.lastTenUmidita.length; i++){
            lastUmid.push(this.lastTenUmidita[i]);
          }
          for(var j=0;j<lastUmid.length;j++){
            this.lastTenUmidita.push(lastUmid[j]);
          }
          this.lastUmid=lastUmid;    
        })
      },
      async inizioRegistrazione() {
        this.axios.post(this.apiUrl + 'apri-azione', {registrazione:this.registrazione}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){ 
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      },
      async fineRegistrazione() {
        this.registrazione= new Date();
        this.axios.post(this.apiUrl + 'chiudi-azione', {registrazione:this.registrazione}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){ 
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            window.location.reload();
            return toast.present();
          }
        })
      },
      getMonitoraggi(){
        this.axios.post(this.apiUrl + 'get-monitoraggi', {}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
              this.monitoraggi=response.data.monitoraggi              
          }
        })
      },
      getLastMonitoraggio(){
        this.axios.post(this.apiUrl + 'get-last-monitoraggio', {}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              this.lastMonitoraggio=response.data.monitoraggio              
            }
        })
      },
    },

    ionViewWillEnter: function(){
      this.getUser();
      this.getTemperature();
      this.getMonitoraggi();
      this.getLastMonitoraggio();
      this.getLastTen();
      this.getUmidita();
      this.getLastTenUmidita();
      }
  }
</script>

<style scoped>
  #container {
    min-height:100%;
    display:block;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap;
    padding:2%;
  }
  ion-grid{
    border:1px solid grey;
    border-radius:5px;
  }
  ion-row{
    border-bottom:1px solid grey;
  }
  #centr{
    border-left: 1px solid grey;
  }
  @media only screen and (max-width: 600px) {
    ion-radio{
      border:2px solid grey;
      border-radius:60px;
    }
  }
</style>